import React from "react";
import { IInputDate } from "./interface";
import DatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns"; // Tambahkan impor ini

import "./input.scss";
const Component: React.FC<IInputDate> = ({
  startDate,
  onChange,
  placeholder,
  dateNow,
}) => {
  return (
    <>
      <div className="w-100">
        <DatePicker
          selected={startDate}
          onChange={onChange}
          showTimeSelect
          excludeTimes={[
            setHours(setMinutes(new Date(), 0), 17),
            setHours(setMinutes(new Date(), 30), 18),
            setHours(setMinutes(new Date(), 30), 19),
            setHours(setMinutes(new Date(), 30), 17),
          ]}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          placeholderText={placeholder || ""}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
    </>
  );
};

export default Component;
