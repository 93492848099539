import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers2";
import { IUserState } from "./types/userTypes";
import { IRoleState } from "./types/roleTypes";
import { IPermState } from "./types/permissionTypes";
import { IPermLoginState } from "./types/permissionLoginTypes";
import { IProfileState } from "./types/profileTypes";
import { IAuthState } from "./types/authTypes";
// import { IReportState } from "./types/reportTypes";
import { IReportState } from "./types/reportTypeV2";
import { IErrorState } from "./types/errorTypes";
import { IAdjState } from "./types/adjudicatorTypes";
import { ITicketState } from "./types/ticketingTypesV2";
import { IActivityState } from "./types/activityTypes";
import { IMiddlewareState } from "./types/middlewareTypes";
import { IRefundState } from "./types/refundTypes";
import { ITrxListState } from "./types/transactionListTypes";
import { IAdditionalFeeState } from "./types/additionalFeeTypes";
import { IDisbursementState } from "./types/disbursementTypes";

let middleware: any[] = [thunk];
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  middleware = [...middleware, logger];
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export interface RootState {
  user: IUserState;
  role: IRoleState;
  permission: IPermState;
  profile: IProfileState;
  auth: IAuthState;
  report: IReportState;
  error: IErrorState;
  adjudicator: IAdjState;
  ticket: ITicketState;
  activity: IActivityState;
  middleware: IMiddlewareState;
  permissionLogin: IPermLoginState;
  refund: IRefundState;
  trxList: ITrxListState;
  additionalFee: IAdditionalFeeState;
  disbursement: IDisbursementState;
}

export { store };
