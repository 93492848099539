import {
  DisbursementAction,
  DisbursementType,
  IDisbursementState,
} from "../types/disbursementTypes";

const initialState = {
  data: null,
  dataDetail: null,
  dataExport: null,
  isLoadingDDl: false,
  isLoading: false,
  isLoadingDetail: false,
  isLoadingExport: false,
  error: null,
  isLoadingCreate: false,
  isLoadingDelete: false,
  isLoadingApproval: false,
};

export default (
  state: IDisbursementState = initialState,
  { type, payload }: DisbursementAction
) => {
  switch (type) {
    case DisbursementType.DISBURSEMENT_PENDING:
      return { ...state, ...payload, error: null };
    case DisbursementType.DISBURSEMENT_SUCCESS:
      return { ...state, ...payload };
    case DisbursementType.DISBURSEMENT_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
